<template>
  <router-view></router-view>
</template>
<style>
html, body, div, h1, h2, h3, h4, p, input {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
</style>
