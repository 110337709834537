import {createRouter, createWebHashHistory} from "vue-router";

const routes = [
	{path: "/", redirect: "/home"},
	{path: '/index', component: () => import('./components/index'),},
	{path: '/home', component: () => import('./components/home'),},
	{path: '/about', component: () => import('./components/about'),},
	{path: '/contact', component: () => import('./components/contact'),},
	{path: '/introduce', component: () => import('./components/introduce'),},

]
export const router = createRouter({
	history: createWebHashHistory(),
	routes: routes
})
